import * as React from "react";
// import { ThemeProvider } from "@mui/material/styles";
import { ThemeProvider } from "@cellectenergy/cellect-react-components";
import CssBaseline from "@mui/material/CssBaseline";
import { CacheProvider } from "@emotion/react";
import createEmotionCache from "./createEmotionCache";
// import theme from "./theme";

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const ThemeWrapper = (props: any) => {
  const { emotionCache = clientSideEmotionCache } = props;

  return (
    <CacheProvider value={emotionCache}>
      {/* <ThemeProvider theme={theme}> */}
      <ThemeProvider>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />

        {props.children}
      </ThemeProvider>
      {/* </ThemeProvider> */}
    </CacheProvider>
  );
};

export default ThemeWrapper;
