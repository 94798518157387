import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useRouter } from "next/router";
import { useState, useEffect, use } from "react";
import { HeadTags } from "@/layouts/HeadTags";

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import TagManager from "react-gtm-module";
import ThemeWrapper from "@/theme/materialConfig";

// Components

const CookieBanner = dynamic(
  () => import("@/components/cookieBanner/CookieBanner")
);
import HotjarScript from "@/components/analytics/HotjarScript";
import { GoogleAnalytics } from "@next/third-parties/google";

// Translations
import global_de from "../translations/de/global.json";
import global_en from "../translations/en/global.json";
import global_es from "../translations/es/global.json";

// Styles
import "@/styles/globals.css";
import "@/styles/about.scss";
import "../theme/main.scss";
import "../../src/styles/iconAnimation.css";
import "animate.css";

// Helpers
import getCookie from "@/helpers/getCookie";

// Types
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";

const getInitialCookiesConsent = (): boolean | undefined => {
  const consent = getCookie("cookiesConsent");
  if (consent === "true") return true;
  if (consent === "false") return false;
  return undefined;
};

export default function App({ Component, pageProps }: AppProps) {
  const [cookiesConsent, setCookiesConsent] = useState<boolean | undefined>(
    getInitialCookiesConsent
  );
  const [showCookiesBanner, setShowCookiesBanner] = useState<
    boolean | undefined
  >(undefined);

  const { locale } = useRouter();

  i18next.init({
    interpolation: { escapeValue: false },
    lng: locale || "en",
    resources: {
      en: {
        global: global_en,
      },
      es: {
        global: global_es,
      },
      de: {
        global: global_de,
      },
    },
  });
  useEffect(() => {
    if (cookiesConsent === undefined) {
      setShowCookiesBanner(true);
    } else {
      setShowCookiesBanner(false);
    }
  }, [cookiesConsent]);

  useEffect(() => {
    if (cookiesConsent === true) {
      TagManager.initialize({ gtmId: "GTM-KLS2QSK" });
    } else if (cookiesConsent === false) {
      // Delete existing non-essential cookies if the user rejects
      document.cookie = "_ga=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
      document.cookie = "_gid=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
      document.cookie = "_gat=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    }
  }, [cookiesConsent]);

  const handleCookieConsent = (consent: boolean) => {
    setCookiesConsent(consent);
    document.cookie = `cookiesConsent=${consent}; path=/; max-age=31536000`; // Save consent in a cookie for 1 year
    if (consent === false) {
      // Delete existing non-essential cookies if the user rejects
      document.cookie = "_ga=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
      document.cookie = "_gid=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
      document.cookie = "_gat=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    }
  };

  return (
    <I18nextProvider i18n={i18next}>
      <HeadTags />
      <ThemeWrapper>
        <HotjarScript />
        <GoogleReCaptchaProvider
          reCaptchaKey="6Le_NVQiAAAAAICg36Gi-LIbY9IbaXORJl0eVCop"
          scriptProps={{
            async: false,
            defer: false,
            appendTo: "head",
            nonce: undefined,
          }}
        >
          {cookiesConsent && <GoogleAnalytics gaId="G-J7ZMB5YYW1" />}
          <Component {...pageProps} />
        </GoogleReCaptchaProvider>
        {showCookiesBanner && (
          <CookieBanner
            setCookiesConsent={handleCookieConsent}
            setShowCookiesBanner={setShowCookiesBanner}
          />
        )}
      </ThemeWrapper>
    </I18nextProvider>
  );
}
