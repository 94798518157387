const getCookie = (name: string): string | null => {
  if (typeof document === "undefined") {
    return null;
  }
  const cookieArr = document.cookie.split(";").map((cookie) => cookie.trim());

  for (const cookie of cookieArr) {
    const [key, value] = cookie.split("=");

    if (name === key) {
      try {
        return decodeURIComponent(value);
      } catch (e) {
        console.error("Error decoding cookie value:", e);
        return null;
      }
    }
  }

  return null;
};

export default getCookie;
