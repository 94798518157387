import { FC } from "react";
import Head from "next/head";

export const HeadTags: FC = () => {
  return (
    <Head>
      <meta key="title" property="og:title" content="Cellect Energy" />
      <meta
        key="description"
        name="description"
        property="og:description"
        content="Cellect Energy is a platform that helps you optimize your business"
      />
      <meta
        key="image"
        property="og:image"
        content={`${process.env.NEXT_PUBLIC_CORPORATE_BASE_PATH}/assets/logo-dark-meta.png`}
      />
      <meta key="url" property="og:url" content="https://cellectenergy.com/" />
      <meta property="og:site_name" content="Cellect Energy" />
      <meta property="og:type" content="website" />
    </Head>
  );
};
